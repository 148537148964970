/* eslint-disable @typescript-eslint/no-explicit-any */
import usePostMsgContext from '@apps/containers/WebviewProvider/hooks';
import { Badge } from '@stack/ui/components';
import classNames from 'classnames';
import Link from 'next/link';

import Contents from '../Content';
import StyledImage from '../Image';

import { ImageContentProps } from './props';
import {
  BadgeHolder,
  ImageContentWrapper,
  ImageHoverWrapper,
  ImageWrapper,
} from './styles';

export const ImageContent: React.FC<ImageContentProps> = (props) => {
  // ======================= PROPS
  const {
    imagePosition = 'top',
    mobileImagePostion = 'top',
    tabletImagePosition = 'top',
    imageProps,
    badgeLabel,
    simpleMobileView = false,
    maxImageWidth,
    maxTabletImageWidth,
    href = '/',
    loading = false,
    displayImage = true,
    webView = false,
    ...restProps
  } = props;

  const { clickEvent } = usePostMsgContext();

  const hasImage = !!imageProps?.src;
  const flexDirection =
    imagePosition === 'right'
      ? 'row-reverse'
      : imagePosition === 'left'
      ? 'row'
      : 'column';
  const tabletDirection =
    tabletImagePosition === 'right'
      ? 'row-reverse'
      : tabletImagePosition === 'left'
      ? 'row'
      : 'column';
  const mobileDirection =
    mobileImagePostion === 'right'
      ? 'row-reverse'
      : mobileImagePostion === 'left'
      ? 'row'
      : 'column';
  const simpleMbView = simpleMobileView;
  const { title, footerText, author, ...restContentProps } = restProps;

  if (loading) {
    return (
      <ImageContentWrapper
        $flexDirection={flexDirection}
        $mobileDirection={mobileDirection}
        $tabletDirection={tabletDirection}
      >
        <ImageWrapper
          $aspectRatio={(imageProps?.width ?? 1) / (imageProps?.height ?? 1)}
          $width={imagePosition !== 'left' ? `100%` : `${imageProps?.width}px`}
          $tabletWidth={tabletImagePosition === 'top' ? '100%' : '30%'}
          $mobileWidth={'100%'}
          $maxWidth={maxImageWidth ? maxImageWidth : undefined}
          $maxLgWidth={maxTabletImageWidth}
        >
          <div
            className={classNames('display-none', {
              'display-block skeleton skeleton-overlay': loading,
            })}
          />
        </ImageWrapper>
        <Contents
          title={''}
          footerText={''}
          loading={loading}
          simpleMbView={simpleMbView}
          webView={webView}
          titleLine={restContentProps.titleLine}
          hideDescription={restContentProps.hideDescription}
        />
      </ImageContentWrapper>
    );
  }

  // ======================= VIEWS
  return (
    <ImageContentWrapper
      $flexDirection={flexDirection}
      $mobileDirection={mobileDirection}
      $tabletDirection={tabletDirection}
    >
      {hasImage && displayImage && (
        <ImageWrapper
          $aspectRatio={(imageProps?.width ?? 1) / (imageProps?.height ?? 1)}
          $width={imagePosition !== 'left' ? `100%` : `${imageProps?.width}px`}
          $tabletWidth={tabletImagePosition === 'top' ? '100%' : '30%'}
          $mobileWidth={simpleMbView ? '60%' : '100%'}
          $maxWidth={maxImageWidth ? maxImageWidth : undefined}
          $maxLgWidth={
            tabletImagePosition === 'top' ? '100%' : maxTabletImageWidth
          }
        >
          <div
            className={classNames('display-none', {
              // 'display-block loader': loading,
            })}
          />
          <div
            className={classNames('display-none', {
              'display-block skeleton-overlay': loading,
            })}
          />
          {badgeLabel && (
            <BadgeHolder
              $simpleMbView={simpleMbView}
              className={classNames('', {
                'display-none': loading,
              })}
            >
              <Badge label={badgeLabel} />
            </BadgeHolder>
          )}
          <Link
            href={href}
            className={classNames('', {
              'display-none': loading,
            })}
            onClick={clickEvent(href)}
          >
            <ImageHoverWrapper>
              <StyledImage {...imageProps} />
            </ImageHoverWrapper>
          </Link>
        </ImageWrapper>
      )}
      <Contents
        title={title}
        footerText={footerText}
        author={author}
        href={href}
        {...restContentProps}
        simpleMbView={simpleMbView}
        badgeLabel={!hasImage ? badgeLabel : undefined}
        loading={loading}
        webView={webView}
      />
    </ImageContentWrapper>
  );
};

// ======================= EXPORT
export * from './props';
export default ImageContent;
