import styled from 'styled-components';

export const StyledTitle = styled.h3<{
  $whiteText?: boolean;
  $simpleMbView?: boolean;
  $titleLine?: number;
  $isList?: boolean;
  $loading?: boolean;
}>`
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => (props.$whiteText ? '#FFF' : '#1E242F')};
  margin: 0;
  overflow: hidden;
  text-overflow: ${(props) => (props.$loading ? 'unset' : 'ellipsis')};
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.$titleLine ?? 3};
  -webkit-box-orient: ${(props) => (props.$loading ? 'unset' : 'vertical')};
  min-height: ${(props) =>
    props.$isList ? 'unset' : (props.$titleLine ?? 3) * 1.5 + 'rem'};
  @media only screen and (max-width: 768px) {
    --line-clamp: ${(props) => (props.$simpleMbView ? 2 : props.$titleLine)};
    --max-height: ${(props) =>
      props.$simpleMbView
        ? `${2 * 1.5}rem`
        : `${(props.$titleLine ?? 3) * 1.6}rem`};
    -webkit-line-clamp: var(--line-clamp);
    max-height: ${(props) =>
      props.$simpleMbView ? 'var(--max-height)' : undefined};
    min-height: unset;
  }
`;

export const StyledTitleTag = styled.span`
  position: relative;
  display: inline;
  vertical-align: baseline;
  color: #31acbf;
`;

const h4LineHeight = 1.381;

export const StyledDescription = styled.h4<{
  $whiteText?: boolean;
  $simpleMbView?: boolean;
  $descriptionLine?: number;
  $hideDescription?: boolean;
  $isList?: boolean;
  $loading?: boolean;
}>`
  font-size: 0.813rem;
  font-weight: 400;
  line-height: ${h4LineHeight}rem;
  letter-spacing: 0px;
  text-align: left;
  color: ${(props) => (props.$whiteText ? '#FFF' : '#1E242F')};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.$descriptionLine ?? 2};
  -webkit-box-orient: ${(props) => (props.$loading ? 'unset' : 'vertical')};
  width: 100%;
  min-height: ${(props) =>
    props.$isList
      ? 'unset'
      : (props.$descriptionLine ?? 3) * h4LineHeight + 'rem'};
  max-height: ${(props) => (props.$descriptionLine ?? 2) * h4LineHeight}rem;

  @media only screen and (max-width: 768px) {
    -webkit-line-clamp: 3;
    min-height: unset;
    display: ${(props) =>
      props.$simpleMbView || props.$hideDescription ? 'none' : 'inherit'};
  }
`;

export const StyledFooterText = styled.h5<{ $whiteText?: boolean }>`
  font-size: 0.688rem;
  font-weight: 400;
  line-height: 1.169rem;
  letter-spacing: 0px;
  text-align: left;
  color: ${(props) => (props.$whiteText ? '#FFF' : '#282828')};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const StyledAuthorText = styled.h5<{ $whiteText?: boolean }>`
  font-size: 0.688rem;
  font-weight: 700;
  line-height: 1.169rem;
  letter-spacing: 0px;
  text-align: left;
  color: ${(props) => (props.$whiteText ? '#FFF' : '#282828')};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  width: 100%;
`;

export const ContentSkeletonWrapper = styled.div<{ $simpleMbView?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  width: 100%;
  height: 170px;

  @media only screen and (max-width: 768px) {
    height: ${(props) => (props.$simpleMbView ? '65px' : '170px')};
  }
`;

export const TitleSkeletonWrapper = styled.div<{ $simpleMbView?: boolean }>`
  min-height: 3rem;

  @media only screen and (max-width: 768px) {
    min-height: ${(props) => (props.$simpleMbView ? '2rem' : '4rem')};
    max-height: ${(props) => (props.$simpleMbView ? '2rem' : '4rem')};
  }
`;

export const ContentBody = styled.div<{
  $isList?: boolean;
}>`
  display: flex;
  flex-wrap: ${(props) => (props.$isList ? 'nowrap' : 'wrap')};
  flex-direction: ${(props) => (props.$isList ? 'column' : 'unset')};
  gap: ${(props) => (props.$isList ? '0.5rem' : '0.438rem')};
  width: 100%;

  @media only screen and (max-width: 768px) {
    gap: 0.5rem;
  }
`;

export const ContentFooter = styled.div<{ $justifyContent?: string }>`
  width: 100%;
  display: flex;
  justify-content: ${(props) => props.$justifyContent ?? 'space-between'};
  align-items: center;
  gap: 1rem;

  @media only screen and (max-width: 1024px) {
    gap: ${(props) =>
      props.$justifyContent === 'space-between' ? '0rem' : '1rem'};
  }

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    gap: 0.3rem;
  }
`;

export const AuthorWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.25rem;
`;

export const AvatarWrap = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  // background-color: #f2f2f299;
`;

export const StyledIcon = styled.span<{ $whiteText?: boolean }>`
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  vertical-align: middle;
`;

export default StyledTitle;
