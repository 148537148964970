import styled from 'styled-components';

export const ImageContentWrapper = styled.div<{
  $flexDirection?: string;
  $mobileDirection?: string;
  $tabletDirection?: string;
}>`
  display: flex;
  gap: 1rem;
  flex-direction: ${(props) =>
    props.$flexDirection ? props.$flexDirection : 'column'};

  @media screen and (max-width: 768px) {
    flex-direction: ${(props) =>
      props.$tabletDirection ? props.$tabletDirection : 'column'};
  }

  @media screen and (max-width: 431px) {
    flex-direction: ${(props) =>
      props.$mobileDirection ? props.$mobileDirection : 'column'};
  }
`;

export const ImageWrapper = styled.div<{
  $aspectRatio: number;
  $width?: string;
  $mobileWidth?: string;
  $tabletWidth?: string;
  $maxWidth?: string;
  $maxLgWidth?: string;
}>`
  aspect-ratio: ${(props) => props.$aspectRatio};
  position: relative;
  width: ${(props) => props.$width};
  max-width: ${(props) => props.$maxWidth};

  overflow: hidden;
  // background-color: rgb(209, 209, 209);

  @media screen and (max-width: 1024px) {
    max-width: ${(props) =>
      props.$maxLgWidth ? props.$maxLgWidth : props.$maxWidth};
  }

  @media screen and (max-width: 768px) {
    width: ${(props) => (props.$tabletWidth ? props.$tabletWidth : '100%')};
    max-width: unset;
  }

  @media screen and (max-width: 431px) {
    width: ${(props) => (props.$mobileWidth ? props.$mobileWidth : '100%')};
    max-width: unset;
  }
`;

export const ImageHoverWrapper = styled.div`
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
  height: 100%;

  &:hover {
    transform: scale(1.1);
  }
`;

export const BadgeHolder = styled.div<{
  $simpleMbView?: boolean;
}>`
  position: absolute;
  z-index: 20;
  margin-bottom: 0;
  top: auto;
  right: 0;
  left: 0;

  @media only screen and (max-width: 768px) {
    display: ${(props) => (props.$simpleMbView ? 'none' : 'unset')};
  }
`;
