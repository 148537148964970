export const priceFormat = ({
  price,
  prefix,
  postfix,
}: {
  price?: number | string;
  prefix?: React.ReactNode;
  postfix?: React.ReactNode;
}) => {
  if (!price) return `${prefix ?? ''}${0}${postfix ?? ''}`;
  const [integerPart] = price.toString().split('.');
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  });
  const number = Number(integerPart);
  const displayPrice = formatter.format(number);
  return `${prefix ?? ''}${displayPrice}${postfix ?? ''}`;
};
