/* eslint-disable @typescript-eslint/no-explicit-any */
import Dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

export const dateConversions = (date: string) => {
  if (!date) return;
  Dayjs.extend(relativeTime);
  let dateObj: any;
  const now = new Date() as any;
  const [datePart, timePart, ampm] = date.split(' '); // Split the datetime string into parts
  const [day, month, year] = datePart.split('-').map(Number); // Extract day, month, and year

  if (timePart) {
    // If time is provided, extract hour and minute
    // eslint-disable-next-line prefer-const
    let [hour, minute] = timePart.split(':').map(Number);

    // Adjust for AM/PM
    if (ampm === 'PM' && hour !== 12) hour += 12;
    if (ampm === 'AM' && hour === 12) hour = 0;

    // Create a Date object with time
    dateObj = new Date(year, month - 1, day, hour, minute);
  } else {
    // Create a Date object without time
    dateObj = new Date(year, month - 1, day);
  }

  // Calculate the difference in milliseconds
  const diffMs = now - dateObj;

  // Convert the difference to hours and days
  const diffMinutes = Math.floor(diffMs / (1000 * 60));
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);

  // Determine the output based on the difference
  if (diffHours < 1) {
    if (diffMinutes < 1) return `Just now`;
    return `${diffMinutes} minute${diffMinutes !== 1 ? 's' : ''} ago`;
  } else if (diffHours < 24) {
    return `${diffHours} hour${diffHours !== 1 ? 's' : ''} ago`;
  } else if (diffDays < 7) {
    return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
  } else {
    return `${day}-${month}-${year}`;
  }
};
