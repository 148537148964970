/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';

export const StyledSkeleton: React.FC<any> = (props) => {
  // ======================= PROPS
  const { text, title, description, loading } = props;

  // ======================= VIEWS
  if (loading)
    return (
      <div>
        <span
          className={classNames('skeleton', {
            'skeleton-title-text': title,
            'skeleton-description-text': description,
          })}
        >
          {text}
        </span>
      </div>
    );

  return text;
};

// ======================= EXPORT
export default StyledSkeleton;
