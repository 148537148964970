import { Theme, useMediaQuery } from '@mui/material';

export const useGetResponsive = () => {
  const sm = useMediaQuery((theme: Theme) => theme?.breakpoints?.down('sm'));
  const md = useMediaQuery((theme: Theme) => theme?.breakpoints?.down('md'));
  const lg = useMediaQuery((theme: Theme) => theme?.breakpoints?.down('lg'));
  const xl = useMediaQuery((theme: Theme) => theme?.breakpoints?.down('xl'));
  const isWeb = useMediaQuery((theme: Theme) => theme?.breakpoints?.up('md'));
  const lgUp = useMediaQuery((theme: Theme) => theme?.breakpoints?.up('lg'));
  const smUp = useMediaQuery((theme: Theme) => theme?.breakpoints?.up('sm'));

  return { sm, md, lg, xl, isMobile: md, isWeb, lgUp, smUp };
};
